@import './_variables';

.wm-button {
    display: inline-block;
    a, button {
        display: block;
        font-size: 1.8rem;
        font-weight: 700;
        border: none;
        cursor: pointer;
        padding-right: 4rem;
        padding-left: 4rem;
    }
    &.-wider {
        a, button {
            padding-right: 6rem;
            padding-left: 6rem;
        }
    }

    &.-rounded {
        a, button {
            padding-top: 1.8rem;
            padding-bottom: 1.8rem;
            border-radius: 4rem;
        }
    }
    &.-semiRounded {
        a, button {
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-radius: 2.6rem;
        }
    }

    &.-blueFill {
        a, button {
            background-color: $color-light-blue;
            color: $color-white;
        }
    }

    &.-whiteFill {
        a, button {
            background-color: $color-white;
            color: $color-dark-blue;
        }
    }

    &.-transparentFill {
        a, button {
            border: 3px solid $color-light-blue;
        }
    }

    &.-bigText {
        a, button {
            font-size: 2.5rem;
        }
    }

    &.-biggerText {
        a, button {
            font-size: 3.6rem;
        }
    }

    &.-wide {
        a, button {
            min-width: 35rem;
            text-align: center;
        }
    }

    //used in quiz
    &.-absolute {
        position: absolute;
        top: 6rem;
        left: 6rem;
    }
}

.wm-languageSwitcher {
    position: absolute;
    top: 4rem;
    right: 4rem;

    &__button {
        background: no-repeat;
        border: none;
        padding: 2rem;
    }
}

.wm-modal {
    display: none;
    position: fixed;
    background: $color-white;
    color: $color-dark;
    box-shadow: 0px 10px 15px #00000029;
    width: 730px;
    box-sizing: border-box;
    padding: 8rem;
    border-radius: 29px 29px 0px 29px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.-open {
        display: block;
    }
}

/* .wm-popup {
    
    &__popup {
        display: none;
        position: fixed;
        background: $color-white;
        color: $color-dark;
        box-shadow: 0px 10px 15px #00000029;
        width: 730px;
        box-sizing: border-box;
        padding: 8rem;
        border-radius: 29px 29px 0px 29px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        button {
            position: absolute;
            top: 2.5rem;
            right: 2.5rem;
            width: 4rem;
            height: 4rem;
            border: none;
            border-radius: 100%;     
            background: $color-light-blue;
            color: $color-white;
            font-size: 2.5rem;
        }
    }

    &.-open {
        .wm-popup__popup {
            display: block;
        }
    }

    &.-iframe {
        .wm-popup__popup {
            width: 65vw;
            height: auto;
            padding: 0;
            border-radius: 0;
            
            iframe {
                width: 100%;
                height: 80vh;
                border: none;
            }
        } 
    }
} */

.wm-moreInfo {
    display: flex;
    align-items: flex-start;
    padding-top: 4rem;
    margin-top: 2rem;
    &__bubble {
        position: relative;
        background-color: $color-white;
        color: $color-dark-blue;
        margin-top: -4rem;
        margin-right: 4rem;
        padding: 2rem;
        border-radius: 1.5rem 1.5rem 0;
        svg {
            position: absolute;
            right: 0;
            top: 70%;
            width: 6rem;
            transform: translateX(35%);
        }
    }
    &__qr {
        canvas {
            padding: .5rem;
            background: $color-white;
        }
    }

    &.-ltr {
        flex-direction: row-reverse;
        .wm-moreInfo__bubble {
            margin-right: 0;
            margin-left: 4rem;
            padding: 2rem;
            border-radius: 1.5rem 1.5rem 1.5rem 0;
            svg {
                left: 0;
                right: auto;
                transform: scaleX(-1) translateX(35%);
                /* transform: ; */
            }
        }
    }
}

.wm-loadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    i {
        font-size: 6rem;
        color: $color-light-blue;
        animation: rotation 1s infinite linear;
    }
}
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }