$color-white: #FFFFFF;
$color-dark: #17242A;
$color-dark-blue: #252C4A;
$color-blue: #848DB4;
$color-light-blue: #4196EA;
$color-red: #F8385B;
$color-green: #59C673;

$font-family: 'Montserrat', sans-serif;

$font-size-small: 2rem;

$grid-xlg: 1600px;
$grid-lg: 1200px;
$grid-md: 992px;
$grid-sm: 768px;
$grid-xs: 552px;