.wm-infoModals {
    &__introText {
        padding-left: 4rem;
        margin-bottom: 4rem;
        color: $color-light-blue;
        width: 45ch;        
    }
    &__items {    
        list-style: none;
        margin: 0;
        padding-left: 0;
        column-count: 2;
        column-gap: 2rem;

        &Item {
            padding-bottom: 3rem;
            button {
                border: none;
                background-color: transparent;
                font-size: 3rem;
                font-weight: 700;
                &:after {
                    content: "\f30b";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 400;
                    margin-left: 3rem;
                    color: $color-light-blue;
                }

                &.-open {
                    color: $color-light-blue;
                }
            }
        }
    }

    &__modal {
        display: none;
        position: fixed;
        background: $color-white;
        color: $color-dark;
        box-shadow: 0px 10px 15px #00000029;
        width: 730px;
        box-sizing: border-box;
        padding: 8rem;
        border-radius: 29px 29px 0px 29px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        h3 {
            color: $color-light-blue;
        }
        button {
            position: absolute;
            top: 2.5rem;
            right: 2.5rem;
            width: 4rem;
            height: 4rem;
            border: none;
            border-radius: 100%;     
            background: $color-light-blue;
            color: $color-white;
            font-size: 2.5rem;
        }
        &.-open {
            display: block;
        }
    }
}