@import './../_variables';

.wm-quiz {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
    &__start {
        display: flex;
        flex-direction: column;
        align-items: center;
        > svg {
            max-width: 100%;
            height: auto;
            box-sizing: border-box;
            @media ( max-width: $grid-xs ) {
                padding: 12rem 10rem 4rem;
            } 
        }
        .wm-button {
            margin-top: 4rem;
        }
        .wm-moreInfo {
            position: absolute;
            left: 10rem;
            bottom: 10rem;
        }
    }

    &__question {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        &Main {
            width: 100%;
            max-width: 90rem;;
            margin: 0 auto;
        }
        &Options {
            list-style: none;
            padding: 0;
            margin: 6rem 0;
            button {
                display: block;
                position: relative;
                width: 100%;
                font-size: 2.5rem;
                border: 0.3rem solid $color-light-blue;
                border-radius: 1.7rem;
                margin-bottom: 2rem;
                padding: 2.5rem 5rem;
                background-color: transparent;
                
                @media ( max-width: $grid-sm ) {
                    font-size: 2rem;
                    padding: 2.5rem 2rem;
                }
            }

            &.-twoColumns {
                column-count: 2;
                column-gap: 4rem;
                li {
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;
                }
            }

            &.-showCorrect {
                pointer-events: none;
                button[data-iscorrect=true] {
                    background-color: $color-green;
                    border-color: $color-green;
                    @media ( min-width: $grid-sm ) {
                        &:after {
                            content: "\f00c";
                            font-family: "Font Awesome 5 Pro";
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 3rem;
                            font-size: 120%;
                            font-weight: 300;
                        }
                    }
                }

                button.-clicked:not([data-iscorrect=true]) {
                    background-color: $color-red;
                    border-color: $color-red;
                }
            }
        }
        &Timer {
            margin-bottom: 4rem;
            width: 90rem;
            max-width: 100%;
            &Bar {
                background-color: $color-white;
                border-radius: 2.3rem;
                height: 1.4rem;
                width: 100%;

                &Progress {
                    height: 100%;
                    border-radius: 2.3rem;
                    background: transparent linear-gradient(90deg, #F74F6D 0%, #B96DF0 100%) 0% 0% no-repeat padding-box;
                }
            }
        }

        &Info {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media ( min-width: $grid-lg ) {
                padding: 0 10rem 3rem;
            }
            h1 {
                margin-bottom: 4rem;
            }
            .wp-block-columns {
                display: flex;
                @media ( max-width: $grid-sm ) {
                    flex-wrap: wrap;
                }
                .wp-block-column {
                    width: 50%;
                    padding-right: 3rem;
                    @media ( max-width: $grid-sm ) {
                        width: 100%;
                        padding-right: 0;
                        &:not(:first-child) {
                            margin-top: 4rem;
                        }

                    }
                    .wp-block-image {
                        text-align: center;
                        margin: 0;
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .wm-button {
                margin-top: 3rem;
            }

            .wm-moreInfo {
                position: absolute;
                right: 6rem;
                bottom: 4rem;
            } 
        }
    }

    &__progress {
        &Bar {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            list-style: none;
            margin-left: 0;
            padding-left: 0;
            @media ( max-width: $grid-sm ) {
                width: 45rem; // = 5 dots
            }
            &Dot {
                display: block;
                width: 2.5rem;
                height: 0.8rem;
                border-radius: 2.3rem;
                margin: 1rem;
                padding-bottom: 0;
                background-color: $color-blue;
                opacity: 0.4;
                @media ( max-width: $grid-sm ) {
                    height: 1rem;
                }
                &.-fill {
                    background-color: $color-white;
                    opacity: 1;
                }
            }
        }
    }

    &__end {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 110rem;
        h1 {
            text-align: center;
        }
        Table {
            padding: 6rem;
            @media ( max-width: $grid-sm ) {
                padding: 4rem 0;
            }
            tr {
                td {
                    padding: 0 2rem 2rem;
                    h1 {
                        color: $color-light-blue;
                        text-align: right;
                    }
                    &:first-child {
                        width: 35%;
                    }
                }
            }
        }
    }
}