@import './../_variables';

.wm-start {

    display: flex;
    align-items: center;

    height: 100%;
    &__left {
        width: 66.66666%;
        margin-left: 15rem;
        h1 {
            position: relative;
            font-size: 10.03rem;
            font-weight: 900;
            text-transform: uppercase;
            span {
                color: $color-light-blue;
            }

            &:before {
                content: "";
                display: block;
                width: 36rem;
                height: 36rem;
                position: absolute;
                top: -15rem;
                left: -15rem;
                background-image: url('../../assets/img/icons/stars.svg');
                background-size: contain;
            }
        }

        h3 {
            width: 40rem;
        }
    }

    &__right {
        width: 33.33333%;

        &Navigation {
            list-style: none;
            margin: 0;

            &Item {
                margin-bottom: 4rem;
                text-align: center;
                .wm-button {
                    width: 100%;
                    position: relative;
                    a {
                        padding: 3.8rem;
                        border-radius: 1.6rem;
                        box-shadow: 0px 10px 20px #00000029;
                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translate(-60%, -50%);
                            width: 8.5rem;
                            height: 8.5rem;
                            border-radius: 100%;
                            background-size: contain;
                        }
                        &:after {
                            content: "\f054";
                            font-family: "Font Awesome 5 Pro";
                            font-weight: 300;
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translate(50%, -50%);
                            width: 8.5rem;
                            height: 8.5rem;
                            line-height: 8.5rem;
                            border-radius: 100%;
                            background: $color-white;
                            box-shadow: 0px 3px 10px #00000029;

                        }
                    }
                }

                &.-info {
                    a:before {
                        background-image: url('../../assets/img/icons/info.svg');
                        box-shadow: 0px 3px 25px #B96DF0;
                    }
                }
                &.-quiz {
                    a:before {
                        background-image: url('../../assets/img/icons/quiz.svg');
                        box-shadow: 0px 3px 20px #FFFE0CE0;
                    }
                }
                &.-skvaller {
                    a:before {
                        background-image: url('../../assets/img/icons/skvaller.svg');
                        box-shadow: 0px 3px 25px #00FEF5FC;
                    }
                }
            }
        }
    }
}