@import './_variables';

.wm-mainContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    padding: 11rem 18rem 4rem;
    min-height: 100vh;
    @media ( min-width: $grid-xlg ) {
        height: 100vh;
    }
    @media ( max-width: $grid-lg ) {
        padding: 10rem;
    }
    @media ( max-width: $grid-md ) {
        padding: 12rem 6rem 6rem;
    }
    @media ( max-width: $grid-xs ) {
        padding: 12rem 3rem 4rem;
    }

    &.-mobile {
        justify-content: center;
    }

    &.-bg1 {
        background-color: $color-dark-blue;
        background-image: url('../assets/img/bg/bg1.svg');
        background-position: top;
        background-size: cover;
    }

    &.-bg3 {
        background-image: url('../assets/img/bg/bg3.svg');
        background-position: top;
        background-size: cover;
    }
}

.wm-content {
    flex-basis: 100%;
}

.wm-header {
    display: flex;
    align-items: center;
    padding: 0 0 6rem;
}

.wm-footer {
    display: flex;
    justify-content: flex-end;

    &.-justify {

        &-center {
            justify-content: center;
        }
    }

    &.-absolute {
        position: absolute;
        right: 4rem;
        bottom: 4rem;
    }
}
