@import "../../__variables";
@import "map";
@import "carousel";
@import "buttons";
@import "modals";

ul.wm-infoPages {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 4rem 2rem 0;

    li {
        flex-basis: 33.33333%;
        box-sizing: border-box;
        padding: 3rem;
        text-align: center;
        .wm-button {
            width: 100%;
        }
    }
}