.wm-infoCarousel {

    &__item {
        box-sizing: border-box;
        outline: none;
    }

    &.-slidesPerPage2 {
        margin: 0 -8rem;
        .wm-infoCarousel__item {
            padding: 0 8rem;
        }
    }

    &.-slidesPerPage3 {
        margin: 0 -3rem;
        .wm-infoCarousel__item {
            padding-right: 3rem;
        }
    }

    .slick-arrow {
        width: auto;
        height: auto;
        z-index: 9999;
        background-color: $color-light-blue;
        padding: 3rem;
        border-radius: 1.8rem;
        &.slick-disabled {
            visibility: hidden !important;
        }
        &:before {
            font-family: 'Font Awesome 5 Pro';
            font-size: 8rem;
            font-weight: 300;
            opacity: 1;
        }
        &.slick-prev {
            left: -5rem;
            &:before {
                content: "\f053";
            }
        }
        &.slick-next {
            right: -5rem;
            &:before {
                content: "\f054";
            }
        }
    }
}