@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap');

@import './_variables';
@import './_util';

html {
    font-size: 56%;
    @media ( max-width: $grid-xlg ) {
        font-size: 50%;
    }
    @media ( max-width: $grid-md ) {
        font-size: 54%;
    }
    @media ( max-width: $grid-sm ) {
        font-size: 40%;
    }
    @media ( max-width: $grid-xs ) {
        font-size: 35%;
    }

    * {
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */


    }

    :focus {
        outline: none !important;        
    }

}

body {
    font-size: 2.2rem;
    line-height: 1.5;
    margin: 0;
    color: $color-white ;
    font-family: $font-family;
}

p {
    margin: 0 0 2.5rem 0;
    &:last-child {
        margin-bottom: 0;
    }
}

ul {
    margin: 3rem 0;
    li {
        padding-bottom: 1rem;
    }
}


a, button {
    color: inherit;
    text-decoration: none;
    font-family: $font-family;
}

h1 {
    font-size: 5.1rem;
    line-height: 1.1;
    margin: 0 0 2rem 0;
}

h2 {
    font-size: 4.5rem;
    line-height: 1.25;
    margin: 0 0 2rem 0;
}

h3 {
    font-size: 3.4rem;
    margin: 0 0 2rem 0;
}

h4 {
    font-size: 3.1rem;
    margin: 0;
}

h5 {
    font-size: 2.5rem;
} 