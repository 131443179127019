.wm-infoButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;

    &__columns {
        display: flex;

        &Column {
            padding: 4rem;

            &Heading {
                margin-bottom: 4rem;
            }

            .wm-moreInfo {
                padding-top: 0;
                justify-content: center;
            }
        }
    }
}