.wm-u {

    &-fw {

        &400 {
            font-weight: 400 !important;
            *:not(b):not(strong) {
                font-weight: 400 !important;
            }
        }

        &500 {
            font-weight: 500 !important;
        }
    }

    &-c {

        &Blue {
            color: $color-blue;
        }
    }

}