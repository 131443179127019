
.wm-map {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-basis: 100%;
    height: 100%;
    &__image {
        position: relative;
        img {
            user-select: none;
        }
        svg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            polygon {
                fill: transparent;
                stroke: $color-white;
                &:focus {
                    outline: none;
                }
                &.-active {
                    fill: #2d73b8;
                    outline: none;
                }
            }
        }
        &Tooltip {
            position: absolute;
            background-color: $color-white;
            color: $color-dark;
            opacity: 1 !important;
            padding: 3rem;
            box-shadow: 0px 10px 15px #00000029;
            border-radius: 3rem 3rem 0 3rem;
            width: 40rem;
            transform: translate(-100%, -100%);
            font-size: $font-size-small;
            &:after {
                content: none;
            }
    
            button {
                position: absolute;
                top: 2.5rem;
                right: 2.5rem;
                width: 4rem;
                height: 4rem;
                border: none;
                border-radius: 100%;     
                background: $color-light-blue;
                color: $color-white;
                font-size: 2.5rem;
            }
            h4 {
                color: $color-light-blue;
                margin-bottom: 1rem;
            }
    
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    font-size: 1.8rem;
                }
            }
        }
    }

    

    area {
        &:focus {
            outline-color: $color-dark-blue;      
        }
    }
}